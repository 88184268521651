export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/traceability/g/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/traceability/g/login/2.png")
        }]
      }, {
        title: "产品管理",
        list: [{
          t: "产品管理",
          image: require("@/assets/traceability/g/product/1.png")
        }, {
          t: "产品参数管理",
          image: require("@/assets/traceability/g/product/2.png")
        }, {
          t: "标签管理",
          image: require("@/assets/traceability/g/product/3.png")
        }]
      }, {
        title: "营销管理",
        list: [{
          t: "调查问卷管理",
          image: require("@/assets/traceability/g/marketing/1.png")
        }, {
          t: "优惠券码管理",
          image: require("@/assets/traceability/g/marketing/2.png")
        }, {
          t: "游戏转盘管理",
          image: require("@/assets/traceability/g/marketing/3.png")
        }]
      }, {
        title: "公司管理",
        list: [{
          t: "公司资讯管理",
          image: require("@/assets/traceability/g/company/1.png")
        }, {
          t: "会员账户管理",
          image: require("@/assets/traceability/g/company/2.png")
        }, {
          t: "意见反馈管理",
          image: require("@/assets/traceability/g/company/3.png")
        }]
      }, {
        title: "数据分析",
        list: [{
          t: "产品销量分析",
          image: require("@/assets/traceability/g/data/1.png")
        }, {
          t: "会员数据分析",
          image: require("@/assets/traceability/g/data/2.png")
        }]
      }, {
        title: "溯源管理",
        list: [{
          t: "溯源类型管理",
          image: require("@/assets/traceability/g/source/1.png")
        }, {
          t: "溯源信息管理",
          image: require("@/assets/traceability/g/source/2.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};