export default {
  data: function data() {
    return {
      list: [{
        img: require("@/assets/traceability/a/1.png"),
        t: "生产 ",
        c: "码生成、标签生产、生产赋码"
      }, {
        img: require("@/assets/traceability/a/2.png"),
        t: "包装",
        c: "库单、箱绑定"
      }, {
        img: require("@/assets/traceability/a/3.png"),
        t: "仓储",
        c: "出库单、库存盘点"
      }, {
        img: require("@/assets/traceability/a/4.png"),
        t: "物流",
        c: "运输时间、GPS信息、车牌号、到达时间"
      }, {
        img: require("@/assets/traceability/a/5.png"),
        t: "门店销售",
        c: "销售统计、营业额、库存统计"
      }, {
        img: require("@/assets/traceability/a/6.png"),
        t: "消费者购买",
        c: "消费者信息、购买记录"
      }, {
        img: require("@/assets/traceability/a/7.png"),
        t: "扫码验证",
        c: "位置信息、手机信息、码信息"
      }, {
        img: require("@/assets/traceability/a/8.png"),
        t: "溯源信息",
        c: "产品信息、追溯信息、扫描信息、召回信息"
      }]
    };
  }
};