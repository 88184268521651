export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/traceability/h/icon1.png"),
        t: "仓储物流管理",
        c: "实现产品生产过程，再到产品销售及售后服务的产品全生命周期全程追溯管理。"
      }, {
        icon: require("@/assets/traceability/h/icon2.png"),
        t: "连接ERP系统",
        c: "与各大品牌的ERP通过API进行无缝对接。实现物料产品生产，物流调配，市场营销管理过程与ERP的管理协同。"
      }, {
        icon: require("@/assets/traceability/h/icon3.png"),
        t: "营销管理",
        c: "通过物联网技术的高效精准识别感知，实现了产品物流储运过程及售后服务过程的完善管理。"
      }, {
        icon: require("@/assets/traceability/h/icon4.png"),
        t: "连接CRM系统",
        c: "与各品牌CRM系统通过API进行无缝连接。实现物料产品物流运输配送管理，门店管理过程与CRM的管理协同。"
      }, {
        icon: require("@/assets/traceability/h/icon5.png"),
        t: "信息化改造",
        c: "产品追溯系统让物料与产品具有了数据与身份识别属性，便于生产管理，物流调配和市场营销数字化与信息化。"
      }, {
        icon: require("@/assets/traceability/h/icon6.png"),
        t: "线上及线下新零售",
        c: "产品追溯使线上订货线下供货过程中物流信息数据化，实现产品全程追溯，保障消费者及企业利益。"
      }]
    };
  }
};