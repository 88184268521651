export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        title: "RFID技术",
        text: ["防伪技术是一种先进的智能无线射频识别技术，每一个RFID芯片中都存储了全球唯一的ID编码。常达智能物联RFID芯片，内部采用更安全算法，无法复制。基于动态秘钥+动态数据的动态ID生成技术，确保任意一次防伪认证的数据唯一性，增加了数据的安全性，并支持苹果和安卓手机进行安全认证，应用更广泛。", "该技术可实现单品/批量智能识别、实时防伪查询与验证、多维度的实时信息采集与智能化的全流程管理、具备生产管理、仓储管理、物流管理、全流程信息追溯、大数据分析、精准营销等功能。"]
      }, {
        title: "二维码技术",
        text: ["结合常达智能物联独特的防伪技术和新材料，杜绝了二维码容易被复制和假冒的短板，实现更具成本优势的溯源信息解决方案。"]
      }]
    };
  }
};