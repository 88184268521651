export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        t: "保健品&药品",
        c: "助力企业打造食品安全品牌，为食品建立独特“身份证” 为企业提供整合防伪包装方案。",
        image: require("@/assets/traceability/e/1.jpg")
      }, {
        t: "婴幼儿用品",
        c: "集合视觉防伪技术、防转移材料、RFID技术、区块链四大先进防伪技术，为婴幼儿用品防伪构筑多重安全防伪体系。",
        image: require("@/assets/traceability/e/2.png")
      }, {
        t: "化妆品",
        c: "针对美妆用品提供多重高级物理防伪与数码防伪保护，消费者通过多种防伪验证方式迅速辨别产品真伪，保护企业品牌。",
        image: require("@/assets/traceability/e/3.png")
      }, {
        t: "饮料酒水",
        c: "无论是渠道商还是零售网点，每次开箱扫码的数据都会实时反馈到系统后台，帮助企业解决渠道窜货问题，控制终端乱价情况，维护企业健康发展。",
        image: require("@/assets/traceability/e/4.png")
      }, {
        t: "电子产品",
        c: "常达帮助几百种电子产品利用一物一码来实现防伪营销、拉新促活、终端智能促销等服务。",
        image: require("@/assets/traceability/e/5.png")
      }]
    };
  }
};