export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        img: require("@/assets/traceability/c/1.png"),
        class: "list1"
      }, {
        img: require("@/assets/traceability/c/3.png"),
        class: "list3"
      }, {
        img: require("@/assets/traceability/c/4.png"),
        class: ""
      }, {
        img: require("@/assets/traceability/c/2.png"),
        class: "list2"
      }],
      time: null
    };
  },
  watch: {
    current: {
      immediate: true,
      handler: function handler(current) {
        var _this = this;

        clearTimeout(this.time);
        this.time = setTimeout(function () {
          current++;
          if (current === _this.list.length) current = 0;
          _this.current = current;
        }, 2000);
      }
    }
  },
  methods: {
    // tap(index) {
    //   this.list[index].class = "list2";
    //   this.list[(index + 1) % 3].class = "list3";
    //   this.list[(index + 2) % 3].class = "list1";
    // },
    itemStyle: function itemStyle(i) {
      var current = this.current,
          list = this.list;
      var zIndex;

      if (current - i > 0) {
        zIndex = 20 - (current - i);
      } else {
        zIndex = 20 + (current - i);
      }

      var x;
      var opacity = 0;

      if (current === 0 && i === list.length - 1) {
        x = 0;
        zIndex = 10;
      } else if (current === list.length - 1 && i == 0) {
        x = (current - 1) * 200;
        zIndex = 10;
      } else if (i === current || current !== 0 && current - 1 === i || current !== list.length - 1 && current + 1 === i) {
        x = (i - current + 1) * 200;
      } else {
        x = 200;
      }

      if (i === current) {
        opacity = 1;
      } else if (current !== 0 && current - 1 === i || current === 0 && list.length - 1 === i || current !== list.length - 1 && current + 1 === i || current === list.length - 1 && 0 === i) {
        opacity = 1;
      }

      return {
        left: (x + 30) / 100 + "rem",
        zIndex: zIndex,
        opacity: opacity
      };
    }
  }
};